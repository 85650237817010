import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

export function registerSentry(app: App<Element>, router: Router) {
  if (window.ndt_config.environment === 'staging' || window.ndt_config.environment === 'production') {
    Sentry.init({
      app,
      dsn: 'https://8389f2fe22774bada906a3f0e280b34f@o367273.ingest.sentry.io/4505238477275136',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['app.mosaic.penrose.education', 'mosaic-staging.herokuapp.com', /^\//],
        }),
        new Sentry.Replay(),
      ],
      environment: window.ndt_config.environment,
      logErrors: true,
      attachProps: true,
      trackComponents: true,
      // Unsure which of these we want, so setting them all
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      // Only allow errors from scripts on our site - this may need to change if we use a CDN
      allowUrls: [/https:\/\/app\.mosaic\.penrose\.education/, /https:\/\/mosaic-staging\.herokuapp\.com/],
      // Performance Monitoring
      tracesSampleRate: window.ndt_config.environment === 'production' ? 0.015 : 0,
      // Session Replay
      replaysSessionSampleRate: 0, // Currently only want to get session replays for errors
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    Sentry.setContext('app', {
      version: window.ndt_config.app_version,
    });
  }
}
